import { Comment, Document, Help } from "@/assets/icons.tsx"
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Box,
  Button,
  Flex,
  Group,
  Input,
  Link,
  Text,
  Textarea,
} from "@chakra-ui/react"

const Footer = () => {
  return (
    // TODO: Add links to help and docs once available
    <Box as="footer" w="100%" p={4}>
      <Flex justifyContent="flex-end" alignItems="center">
        <PopoverRoot>
          <PopoverTrigger asChild>
            <Link mx={2} display="flex" alignItems="center" cursor="pointer">
              <Comment />
              <Box as="span" ml={1}>
                Feedback
              </Box>
            </Link>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <PopoverTitle fontWeight="semibold">Feedback</PopoverTitle>
              <Text my={3}>
                Please let us know your thoughts and suggestions to help us
                improve:
              </Text>
              <Input placeholder="Your email" mb={3} />
              <Textarea placeholder="Your comments" mb={3} />
            </PopoverBody>
            <PopoverFooter>
              <Flex justify="flex-end">
                <Group>
                  <Button variant="subtle" colorPalette="gray">
                    Cancel
                  </Button>
                  <Button variant="solid">Submit</Button>
                </Group>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </PopoverRoot>
        <Link mx={2} display="flex" alignItems="center">
          <Help />
          <Box as="span" ml={1}>
            Help
          </Box>
        </Link>
        <Link mx={2} display="flex" alignItems="center">
          <Document />
          <Box as="span" ml={1}>
            Docs
          </Box>
        </Link>
      </Flex>
    </Box>
  )
}

export default Footer
