import { createIcon } from "@chakra-ui/react"

export const Apps = createIcon({
  displayName: "Apps",
  path: (
    <path
      d="M7 0H4a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2ZM20 0h-3a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2ZM7 13H4a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2ZM20 13h-3a4 4 0 0 0-4 4v3a4 4 0 0 0 4 4h3a4 4 0 0 0 4-4v-3a4 4 0 0 0-4-4Zm2 7a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const ArrowDown = createIcon({
  displayName: "ArrowDown",
  path: (
    <path
      d="M18.71,8.21a1,1,0,0,0-1.42,0l-4.58,4.58a1,1,0,0,1-1.42,0L6.71,8.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.59,4.59a3,3,0,0,0,4.24,0l4.59-4.59A1,1,0,0,0,18.71,8.21Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const CheckCircle = createIcon({
  displayName: "CheckCircle",
  path: (
    <path
      d="m18.214,9.098c.387.394.381,1.027-.014,1.414l-4.426,4.345c-.783.768-1.791,1.151-2.8,1.151-.998,0-1.996-.376-2.776-1.129l-1.899-1.867c-.394-.387-.399-1.02-.012-1.414.386-.395,1.021-.4,1.414-.012l1.893,1.861c.776.75,2.001.746,2.781-.018l4.425-4.344c.393-.388,1.024-.381,1.414.013Zm5.786,2.902c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-2,0c0-5.514-4.486-10-10-10S2,6.486,2,12s4.486,10,10,10,10-4.486,10-10Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const Comment = createIcon({
  displayName: "Comment",
  path: (
    <>
      <path
        d="M20 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h2.9l4.451 3.763a1 1 0 0 0 1.292 0L17.1 20H20a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Zm2 16a2 2 0 0 1-2 2h-2.9a2 2 0 0 0-1.291.473L12 21.69l-3.807-3.217A2 2 0 0 0 6.9 18H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M7 7h5a1 1 0 0 0 0-2H7a1 1 0 0 0 0 2ZM17 9H7a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2ZM17 13H7a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Document = createIcon({
  displayName: "Comment",
  path: (
    <path
      d="M17 14a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3H8a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515V19a5.006 5.006 0 0 1-5 5H7a5.006 5.006 0 0 1-5-5V5a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486A6.951 6.951 0 0 1 22 10.485zm-6.949-7.021A5.01 5.01 0 0 0 14 2.684V7a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 0 1-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const Email = createIcon({
  displayName: "Email",
  path: (
    <path
      d="M19,1H5A5.006,5.006,0,0,0,0,6V18a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,2.78,1.887l-7.658,7.659a3.007,3.007,0,0,1-4.244,0L2.22,4.887A3,3,0,0,1,5,3ZM19,21H5a3,3,0,0,1-3-3V7.5L8.464,13.96a5.007,5.007,0,0,0,7.072,0L22,7.5V18A3,3,0,0,1,19,21Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const EmailPending = createIcon({
  displayName: "EmailPending",
  path: (
    <path
      d="m24,14v5c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5v-11C0,5.243,2.243,3,5,3h4c.552,0,1,.447,1,1s-.448,1-1,1h-4c-1.119,0-2.086.623-2.601,1.534l7.587,7.587c1.134,1.133,3.109,1.133,4.243,0l.329-.329c.391-.389,1.023-.39,1.414,0,.39.392.39,1.024,0,1.415l-.329.328c-.944.943-2.199,1.464-3.535,1.464s-2.591-.521-3.535-1.465l-6.571-6.571v10.036c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3v-5c0-.553.448-1,1-1s1,.447,1,1ZM12,6c0-3.309,2.691-6,6-6s6,2.691,6,6-2.691,6-6,6-6-2.691-6-6Zm2,0c0,2.206,1.794,4,4,4s4-1.794,4-4-1.794-4-4-4-4,1.794-4,4Zm4.293,1.707c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-.707-.707v-1.586c0-.553-.448-1-1-1s-1,.447-1,1v2c0,.266.105.52.293.707l1,1Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const EmptyBox = createIcon({
  displayName: "EmptyBox",
  viewBox: "0 0 64 64",
  path: (
    <path
      d="m56.78 30.38-4-5c-.11-.13-.25-.24-.41-.3l-14.61-5.84c.53-.15.84-.69.7-1.21-.14-.53-.69-.85-1.22-.71-.37.1-.74.22-1.1.37-.3.12-.49.39-.57.68l-3.19-1.28a.95.95 0 0 0-.74 0l-20 8c-.16.06-.3.17-.41.3l-4 5c-.2.25-.27.57-.19.88s.3.56.6.67l3.37 1.35v18.72a1 1 0 0 0 .63.93l20 8h.04c.11.04.22.06.34.06s.23-.03.34-.06h.04l20-8a1 1 0 0 0 .63-.93V33.29l3.37-1.35c.29-.12.52-.37.6-.67.08-.31.01-.63-.19-.88zM32 19.08 49.31 26 32 32.92 14.69 26zm-19.68 8.13 18.06 7.22-2.69 3.37-18.06-7.22zm.68 6.87 14.63 5.85c.41.16.88.04 1.15-.3L31 36.86v21.67l-18-7.2zm38 17.25-18 7.2V36.86l2.22 2.77a1 1 0 0 0 1.15.31L51 34.09v17.25zM36.32 37.8l-2.69-3.37 18.06-7.22 2.69 3.37zM18.35 12.92l2.46 2.84c.7.81 1.63 1.24 2.59 1.24.38 0 .77-.07 1.15-.21 1.49-.56 2.46-2.05 2.46-3.8v-.77c.61-.55 1-1.34 1-2.22s-.39-1.67-1-2.22v-.77c0-1.75-.96-3.25-2.46-3.8-1.33-.5-2.76-.1-3.74 1.03l-2.46 2.84A3 3 0 0 0 16 10a3 3 0 0 0 2.35 2.92zM26 10c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1zm-2.16 4.92c-.32.12-.94.22-1.53-.46L21.05 13h3.94c0 1.1-.62 1.72-1.16 1.92zm-1.53-9.37c.58-.68 1.21-.58 1.53-.46.53.2 1.15.82 1.16 1.92h-3.94l1.26-1.46zm8.56 4.46c0-.55.45-1 1-1h.5c.55 0 1 .45 1 1s-.45 1-1 1h-.5c-.55 0-1-.45-1-1zm9.73 8.12c-.08-.55.29-1.05.84-1.14.25-.04.5-.11.74-.21.51-.22 1.1.01 1.32.52s-.01 1.1-.52 1.32c-.4.17-.81.29-1.24.36-.05 0-.1.01-.15.01-.49 0-.91-.36-.99-.85zm.06-8.23c.09-.55.6-.92 1.15-.82.43.07.84.19 1.23.37a1 1 0 0 1-.82 1.82c-.23-.1-.48-.18-.74-.22a.999.999 0 0 1-.82-1.15zm3.31 4.53c.02-.13.03-.27.03-.41 0-.12 0-.25-.02-.36a.987.987 0 0 1 .87-1.11.975.975 0 0 1 1.11.87c.02.2.04.4.04.61 0 .23-.02.45-.04.67-.07.5-.5.87-.99.87h-.13a.995.995 0 0 1-.86-1.12zm-12.08 7.09c.18-.34.39-.67.62-.99.33-.45.95-.54 1.4-.22.45.33.54.95.22 1.4-.17.24-.33.49-.47.75-.18.34-.53.53-.88.53-.16 0-.32-.04-.47-.12a.996.996 0 0 1-.41-1.35zm3.61-11.51c0-.55.45-1 1-1h1.03c.55 0 1 .45 1 1s-.45 1-1 1H36.5c-.55 0-1-.45-1-1zm-4.62 16.5v-.5c0-.55.45-1 1-1s1 .45 1 1v.5c0 .55-.45 1-1 1s-1-.45-1-1z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const Exclamation = createIcon({
  displayName: "Exclamation",
  path: (
    <>
      <path
        d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M12,5a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V6A1,1,0,0,0,12,5Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const ExternalLink = createIcon({
  displayName: "ExternalLink",
  path: (
    <>
      <path
        d="M20,11v8c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V9c0-2.757,2.243-5,5-5H13c.552,0,1,.448,1,1s-.448,1-1,1H5c-1.654,0-3,1.346-3,3v10c0,1.654,1.346,3,3,3H15c1.654,0,3-1.346,3-3V11c0-.552,.448-1,1-1s1,.448,1,1ZM21,0h-7c-.552,0-1,.448-1,1s.448,1,1,1h6.586L8.293,14.293c-.391,.391-.391,1.023,0,1.414,.195,.195,.451,.293,.707,.293s.512-.098,.707-.293L22,3.414v6.586c0,.552,.448,1,1,1s1-.448,1-1V3c0-1.654-1.346-3-3-3Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Eye = createIcon({
  displayName: "Eye",
  path: (
    <>
      <path
        d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const EyeCrossed = createIcon({
  displayName: "EyeCrossed",
  path: (
    <>
      <path
        d="M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Help = createIcon({
  displayName: "Help",
  path: (
    <>
      <path
        d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm0 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M12.717 5.063A4 4 0 0 0 8 9a1 1 0 0 0 2 0 2 2 0 0 1 2.371-1.967 2.024 2.024 0 0 1 1.6 1.595 2 2 0 0 1-1 2.125A3.954 3.954 0 0 0 11 14.257V15a1 1 0 0 0 2 0v-.743a1.982 1.982 0 0 1 .93-1.752 4 4 0 0 0-1.213-7.442Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Home = createIcon({
  displayName: "Home",
  path: (
    <path
      d="m23.121 9.069-7.585-7.586a5.008 5.008 0 0 0-7.072 0L.879 9.069A2.978 2.978 0 0 0 0 11.19v9.817a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V11.19a2.978 2.978 0 0 0-.879-2.121ZM15 22.007H9v-3.934a3 3 0 0 1 6 0Zm7-1a1 1 0 0 1-1 1h-4v-3.934a5 5 0 0 0-10 0v3.934H3a1 1 0 0 1-1-1V11.19a1.008 1.008 0 0 1 .293-.707L9.878 2.9a3.008 3.008 0 0 1 4.244 0l7.585 7.586a1.008 1.008 0 0 1 .293.704Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const Lock = createIcon({
  displayName: "Lock",
  path: (
    <>
      <path
        d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const LogOut = createIcon({
  displayName: "LogOut",
  path: (
    <>
      <path
        d="M11.476 15a1 1 0 0 0-1 1v3a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h2.476a3 3 0 0 1 3 3v3a1 1 0 0 0 2 0V5a5.006 5.006 0 0 0-5-5H5a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h2.476a5.006 5.006 0 0 0 5-5v-3a1 1 0 0 0-1-1Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="m22.867 9.879-4.586-4.586a1 1 0 1 0-1.414 1.414l4.262 4.263L6 11a1 1 0 0 0 0 2l15.188-.031-4.323 4.324a1 1 0 1 0 1.414 1.414l4.586-4.586a3 3 0 0 0 .002-4.242Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Moon = createIcon({
  displayName: "Moon",
  path: (
    <>
      <path
        d="M12.009 24A12.067 12.067 0 0 1 .075 10.725 12.121 12.121 0 0 1 10.1.152a13 13 0 0 1 5.03.206 2.5 2.5 0 0 1 1.8 1.8 2.47 2.47 0 0 1-.7 2.425c-4.559 4.168-4.165 10.645.807 14.412a2.5 2.5 0 0 1-.7 4.319 13.875 13.875 0 0 1-4.328.686Zm.074-22a10.776 10.776 0 0 0-1.675.127 10.1 10.1 0 0 0-8.344 8.8A9.928 9.928 0 0 0 4.581 18.7a10.473 10.473 0 0 0 11.093 2.734.5.5 0 0 0 .138-.856C9.883 16.1 9.417 8.087 14.865 3.124a.459.459 0 0 0 .127-.465.491.491 0 0 0-.356-.362A10.68 10.68 0 0 0 12.083 2ZM20.5 12a1 1 0 0 1-.97-.757l-.358-1.43-1.432-.385a1 1 0 0 1 .035-1.94l1.4-.325.351-1.406a1 1 0 0 1 1.94 0l.355 1.418 1.418.355a1 1 0 0 1 0 1.94l-1.418.355-.355 1.418A1 1 0 0 1 20.5 12ZM16 14a1 1 0 0 0 2 0 1 1 0 0 0-2 0Zm6 4a1 1 0 0 0 2 0 1 1 0 0 0-2 0Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const NotFound = createIcon({
  displayName: "NotFound",
  path: (
    <>
      <path
        d="m16.707,13.207l-.543.543.543.543c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-.543-.543-.543.543c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l.543-.543-.543-.543c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l.543.543.543-.543c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Zm-7.457,1.957l.543.543c.195.195.451.293.707.293s.512-.098.707-.293c.391-.391.391-1.023,0-1.414l-.543-.543.543-.543c.391-.391.391-1.023,0-1.414s-1.023-.391-1.414,0l-.543.543-.543-.543c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l.543.543-.543.543c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l.543-.543ZM4.5,7c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5,1.5.672,1.5,1.5,1.5Zm4,0c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5,1.5.672,1.5,1.5,1.5Zm15.5-1v12c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V6C0,3.243,2.243,1,5,1h14c2.757,0,5,2.243,5,5Zm-22,0v2h20v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3Zm20,12v-8H2v8c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3Zm-7.143-.434c-1.994-.762-3.72-.762-5.714,0-.516.197-.774.775-.577,1.291.197.517.776.776,1.291.577,1.53-.584,2.756-.584,4.286,0,.118.045.238.066.357.066.402,0,.782-.245.934-.644.197-.516-.062-1.094-.577-1.291Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Plus = createIcon({
  displayName: "Plus",
  path: (
    <>
      <path
        d="M18,12H14V8a1,1,0,0,0-2,0v4H8a1,1,0,0,0,0,2h4v4a1,1,0,0,0,2,0V14h4a1,1,0,0,0,0-2Z"
        fill="currentColor"
        opacity="1"
        strokeWidth="2"
        data-original="#000000"
      />
    </>
  ),
})

export const Restore = createIcon({
  displayName: "Restore",
  path: (
    <>
      <path d="M12,0A11.972,11.972,0,0,0,4,3.073V1A1,1,0,0,0,2,1V4A3,3,0,0,0,5,7H8A1,1,0,0,0,8,5H5a.854.854,0,0,1-.1-.021A9.987,9.987,0,1,1,2,12a1,1,0,0,0-2,0A12,12,0,1,0,12,0Z" />
      <path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z" />
    </>
  ),
})

export const Settings = createIcon({
  displayName: "Settings",
  path: (
    <>
      <path
        d="M12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="m21.294 13.9-.444-.256a9.1 9.1 0 0 0 0-3.29l.444-.256a3 3 0 1 0-3-5.2l-.445.257A8.977 8.977 0 0 0 15 3.513V3a3 3 0 0 0-6 0v.513a8.977 8.977 0 0 0-2.848 1.646L5.705 4.9a3 3 0 0 0-3 5.2l.444.256a9.1 9.1 0 0 0 0 3.29l-.444.256a3 3 0 1 0 3 5.2l.445-.257A8.977 8.977 0 0 0 9 20.487V21a3 3 0 0 0 6 0v-.513a8.977 8.977 0 0 0 2.848-1.646l.447.258a3 3 0 0 0 3-5.2Zm-2.548-3.776a7.048 7.048 0 0 1 0 3.75 1 1 0 0 0 .464 1.133l1.084.626a1 1 0 0 1-1 1.733l-1.086-.628a1 1 0 0 0-1.215.165 6.984 6.984 0 0 1-3.243 1.875 1 1 0 0 0-.751.969V21a1 1 0 0 1-2 0v-1.252a1 1 0 0 0-.751-.969A6.984 6.984 0 0 1 7.006 16.9a1 1 0 0 0-1.215-.165l-1.084.627a1 1 0 1 1-1-1.732l1.084-.626a1 1 0 0 0 .464-1.133 7.048 7.048 0 0 1 0-3.75 1 1 0 0 0-.465-1.129l-1.084-.626a1 1 0 0 1 1-1.733l1.086.628A1 1 0 0 0 7.006 7.1a6.984 6.984 0 0 1 3.243-1.875A1 1 0 0 0 11 4.252V3a1 1 0 0 1 2 0v1.252a1 1 0 0 0 .751.969A6.984 6.984 0 0 1 16.994 7.1a1 1 0 0 0 1.215.165l1.084-.627a1 1 0 1 1 1 1.732l-1.084.626a1 1 0 0 0-.463 1.129Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Sun = createIcon({
  displayName: "Sun",
  path: (
    <path
      d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Zm0-8c-1.65,0-3,1.35-3,3s1.35,3,3,3,3-1.35,3-3-1.35-3-3-3Zm1-5V1c0-.55-.45-1-1-1s-1,.45-1,1v3c0,.55,.45,1,1,1s1-.45,1-1Zm0,19v-3c0-.55-.45-1-1-1s-1,.45-1,1v3c0,.55,.45,1,1,1s1-.45,1-1ZM5,12c0-.55-.45-1-1-1H1c-.55,0-1,.45-1,1s.45,1,1,1h3c.55,0,1-.45,1-1Zm19,0c0-.55-.45-1-1-1h-3c-.55,0-1,.45-1,1s.45,1,1,1h3c.55,0,1-.45,1-1ZM6.71,6.71c.39-.39,.39-1.02,0-1.41l-2-2c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l2,2c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Zm14,14c.39-.39,.39-1.02,0-1.41l-2-2c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l2,2c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Zm-16,0l2-2c.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0l-2,2c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29ZM18.71,6.71l2-2c.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0l-2,2c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})

export const Trash = createIcon({
  displayName: "Trash",
  path: (
    <>
      <path
        d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const User = createIcon({
  displayName: "User",
  path: (
    <>
      <path
        d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
      <path
        d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Users = createIcon({
  displayName: "Users",
  path: (
    <>
      <path
        d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z"
        fill="currentColor"
        opacity="1"
        data-original="#000000"
      />
    </>
  ),
})

export const Warning = createIcon({
  displayName: "Warning",
  path: (
    <path
      d="M11,13V7c0-.55,.45-1,1-1s1,.45,1,1v6c0,.55-.45,1-1,1s-1-.45-1-1Zm1,2c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Zm11.58,4.88c-.7,1.35-2.17,2.12-4.01,2.12H4.44c-1.85,0-3.31-.77-4.01-2.12-.71-1.36-.51-3.1,.5-4.56L8.97,2.6c.71-1.02,1.83-1.6,3.03-1.6s2.32,.58,3,1.57l8.08,12.77c1.01,1.46,1.2,3.19,.49,4.54Zm-2.15-3.42s-.02-.02-.02-.04L13.34,3.67c-.29-.41-.79-.67-1.34-.67s-1.05,.26-1.36,.71L2.59,16.42c-.62,.88-.76,1.84-.4,2.53,.35,.68,1.15,1.05,2.24,1.05h15.12c1.09,0,1.89-.37,2.24-1.05,.36-.69,.22-1.65-.37-2.49Z"
      fill="currentColor"
      opacity="1"
      data-original="#000000"
    />
  ),
})
